import React from "react"
import { Link } from "gatsby"
import chroma from "chroma-js"

import "./Card.css"

const Card = ({ card }) => {
  const renderTitle = title => {
    if (title.length > 54) {
      return `${title.substring(0, 54)}...`
    }
    return title
  }

  if (card.node.type === "quote") {
    const bgColor = chroma(card.node.topic.color).brighten(2)
    const titleColor = chroma(card.node.topic.color).darken()

    return (
      <Link
        to={`/en/cards/${card.node.slug}`}
        className="card-quote"
        style={{ backgroundColor: bgColor }}
      >
        <span
          className="card-quote-topic"
          style={{ color: card.node.topic.color }}
        >
          {card.node.topic.name}
        </span>

        <h3 style={{ color: titleColor }}>{card.node.name}</h3>

        <div className="card-quote-info">
          <div
            className="card-quote-img"
            style={{
              backgroundImage: `url(${card.node.imageUrl})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          ></div>
          <span style={{ color: card.node.topic.color }}>
            {card.node.meta.author}
          </span>
        </div>

        <div className="card-quote-tags">
          {card.node.tags.slice(0, 2).map(tag => (
            <span>{tag.name}</span>
          ))}
        </div>
      </Link>
    )
  }

  return (
    <Link to={`/en/cards/${card.node.slug}`} className="card">
      <div
        className="card-img"
        style={{
          backgroundImage: `url(${card.node.imageUrl})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      ></div>
      <div className="card-info">
        <span style={{ color: card.node.topic.color }}>
          {card.node.topic.name}
        </span>
        <h3>{renderTitle(card.node.name)}</h3>

        <div className="card-tags">
          {card.node.tags.slice(0, 2).map(tag => (
            <span>{tag.name}</span>
          ))}
        </div>
      </div>
    </Link>
  )
}

export default Card
